import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const ImageStack = (props) => {
  const {
    main,
    over,
    locked,
    lockedLabel,
    reveal,
  } = props;

  return (
    <BackgroundImage className={`image-stack main ${reveal ? 'reveal' : ''}`} fluid={main.childImageSharp.fluid}>
      <Img fluid={over ? over.childImageSharp.fluid : main.childImageSharp.fluid} className={`${over ? 'over' : 'main'}`} />
      { locked && (
        <div className="lock-overlay">
          <h3 className={`lock-label ${lockedLabel.length <= 3 ? 'icon silhouette' : ''}`}>{lockedLabel}</h3>
        </div>
      )}
    </BackgroundImage>
  );
};

ImageStack.defaultProps = {
  over: null,
  locked: false,
  lockedLabel: null,
  reveal: false,
};

ImageStack.propTypes = {
  locked: PropTypes.bool,
  lockedLabel: PropTypes.string,
  reveal: PropTypes.bool,
  main: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  over: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

export default ImageStack;
